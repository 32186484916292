import * as React from "react"

import "./Header.scss"

const Header: React.FunctionComponent = () => {
  return (
    <section className="nous-trouver__header section-padding">
      <div className="container">
        <h1 className="heading heading--section-title">Où nous Trouver</h1>
      </div>
    </section>
  )
}

export default Header
