import * as React from "react"

import Map from "../../Map"
import pin from "../../../images/map/pin.png"
import pin_restrictive from "../../../images/map/pin_restrictive.png"
import "./NousTrouverMap.scss"

export type FilterState = "btn-all" | "btn-public" | "btn-restricted"

const NousTrouverMap: React.FunctionComponent = () => {
  const [activeFilter, setActiveFilter] = React.useState<FilterState>("btn-all")
  const handleClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const targetId = e.currentTarget.id as FilterState
    setActiveFilter(targetId)
  }
  return (
    // Important! Always set the container height explicitly
    <section className="nous-trouver__map container">
      <p className="nous-trouver__tagline">
        Si vous cherchez à connaître nos partenaires ou à restituer votre
        emballage, vous êtes au bon endroit. Trouvez un point de
        collecte ou un partenaire autour de vous !
      </p>
      <section className="input hidden-for-the-moment">
        <div className="input__elements">
          <input
            className="map-input"
            type="text"
            name=""
            id=""
            placeholder="Adresse, métro..."
          />
          <button
            className="button button--color-pink"
            onClick={onClickChercher}
          >
            Chercher
          </button>
        </div>
      </section>
      <div className="map-filters">
        <div
          id="btn-public"
          onClick={handleClick}
          className={`map-filters__filter button button--color-white ${
            activeFilter === "btn-public" ? "active" : ""
          }`}
        >
          <img src={pin} width={30} />
          <p>Réseau public</p>
        </div>
        <div
          id="btn-restricted"
          onClick={handleClick}
          className={`map-filters__filter button button--color-white ${
            activeFilter === "btn-restricted" ? "active" : ""
          }`}
        >
          <img src={pin_restrictive} width={30} />
          <p>Réseau privé</p>
        </div>
        <div
          id="btn-all"
          onClick={handleClick}
          className={`map-filters__filter button button--color-white ${
            activeFilter === "btn-all" ? "active" : ""
          }`}
        >
          <p>Tous les partenaires</p>
        </div>
      </div>
      <section className="map__elements">
        <div className="map">
          <Map activeFilter={activeFilter} />
        </div>
      </section>
    </section>
  )
}

export default NousTrouverMap

const onClickChercher = () => {}
