import React from "react"

import Layout from "../components/Layout"
import Header from "../components/pages/nous-trouver/Header"
import NousTrouverMap from "../components/pages/nous-trouver/NousTrouverMap"
import SEO from "../components/Seo"

const NousTrouver = () => (
  <Layout activePage="nous-trouver">
    <SEO
      title="Nous Trouver"
      description="La Consigne GreenGo est une app' mobile permettant d’emprunter facilement des emballages réutilisables et consignés dans tout type de structures : restaurants, cafétérias d’entreprises et grande distribution."
      keywords={["consigne", "emballage", "collecteur", "restauration"]}
    />
    <Header />
    <NousTrouverMap />
  </Layout>
)

export default NousTrouver
